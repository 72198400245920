import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface PositionedSnackbarProps {
  open: boolean;
  message: string;
  color?: string;
}

export default function PositionedSnackbar({ open, message, color = "#d32f2f" }: PositionedSnackbarProps) {
  const [isOpen, setIsOpen] = React.useState(open);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={isOpen}
      message={message}
      key="bottomleft"
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: color, 
          color: "#fff",
        },
      }}
      action={
        <IconButton size="small" color="inherit" onClick={() => setIsOpen(false)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
