import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.tsx";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Nuevo estado para el mensaje de éxito
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);

  const handleSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      setCookie("user", await user.user.getIdToken(), {
        path: "/",
        maxAge: 1728588542417,
      });
      setSuccessMessage("¡Inicio de sesión exitoso! Bienvenido/a."); // Mensaje de éxito
      setTimeout(() => {
        setSuccessMessage(null); // Desaparece después de 3 segundos
        navigate("/home"); // Navega a la página de inicio después del éxito
      }, 3000);
    } catch (error) {
      setError("Credenciales incorrectas. Inténtalo de nuevo.");
      console.error(error);
    }
  };

  return (
    <div style={styles.container}>
      <main style={styles.formContainer}>
        <form onSubmit={handleSubmitLogin} style={styles.form}>
          <img
            src="./OSSEG_22.webp"
            alt="Galeno logo"
            width="310"
            height="100"
            style={styles.logo}
          />
          {error && <div style={styles.error}>{error}</div>}
          {successMessage && (
            <div style={styles.success}>{successMessage}</div>
          )}{" "}
          {/* Mostrar el mensaje de éxito */}
          <div style={styles.inputGroup}>
            <label htmlFor="inputEmail" style={styles.label}>
              Correo electrónico
            </label>
            <input
              type="email"
              id="inputEmail"
              name="email"
              style={styles.input}
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="inputPassword" style={styles.label}>
              Contraseña
            </label>
            <input
              type="password"
              id="inputPassword"
              name="password"
              style={styles.input}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div style={styles.checkboxGroup}>
            <input
              type="checkbox"
              id="flexCheckDefault"
              style={styles.checkbox}
            />
            <label htmlFor="flexCheckDefault" style={styles.checkboxLabel}>
              Recuérdame
            </label>
          </div>
          <button type="submit" style={styles.button}>
            Iniciar sesión
          </button>
          <p style={styles.footerText}>
            &copy;{" "}
            <a
              href="https://beeneu.dev"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Beeneu
            </a>
            , 2024.
          </p>
        </form>
      </main>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f9",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  },
  form: {
    width: "300px",
  },
  logo: {
    display: "block",
    margin: "0 auto 1rem",
  },
  error: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    padding: "0.5rem",
    marginBottom: "1rem",
    borderRadius: "4px",
    textAlign: "center",
  },
  success: {
    backgroundColor: "#d4edda" /* Fondo verde claro */,
    color: "#155724" /* Texto verde oscuro */,
    padding: "0.5rem",
    marginBottom: "1rem",
    borderRadius: "4px",
    textAlign: "center",
    border: "1px solid #c3e6cb" /* Borde verde claro */,
    transition:
      "opacity 0.5s ease-in-out" /* Transición suave para mostrar/ocultar */,
  },
  inputGroup: {
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    marginBottom: "0.5rem",
    color: "#555",
  },
  input: {
    width: "100%",
    padding: "0.75rem",
    borderRadius: "4px",
    border: "1px solid #ddd",
    fontSize: "1rem",
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  checkbox: {
    marginRight: "0.5rem",
  },
  checkboxLabel: {
    color: "#555",
  },
  button: {
    width: "100%",
    padding: "0.75rem",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  footerText: {
    marginTop: "1rem",
    textAlign: "center",
    color: "#888",
  },
};
export default Login;
