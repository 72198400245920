import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { useGetAfiliadosQueryMutation } from "../Redux/api/AfiliatesApi.tsx";
import { useEffect, useState } from "react";
import { StateAutocomplete } from "../componentes/StateAutocomplete.tsx";
import { TextInput } from "../componentes/TextInput.tsx";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PositionedSnackbar from "../componentes/PositionedSnackbar.tsx";

const columns: GridColDef[] = [
  { field: "id__", headerName: "ID", width: 70 },
  {
    field: "DOCDATE_REM",
    headerName: "Fecha doc",
    width: 150,
    valueFormatter: (value: string) =>
      value !== null ? value.split(" ")[0] : "",
  },
  {
    field: "U_NroAfiliado",
    headerName: "Nro Afiliado",
    width: 150,
    valueFormatter: (value: string) => value.split("%")[1],
  },
  { field: "U_NombrePaciente", headerName: "Nombre", width: 260 },
  { field: "U_ApellidoPaciente", headerName: "Apellido", width: 220 },
  { field: "U_NOMBRE_FD", headerName: "Nombre FD", width: 350 },
  { field: "U_REMITO", headerName: "Remito", width: 260 },
  { field: "ESTADO", headerName: "Estado", width: 200 },
  { field: "Dscription", headerName: "Desc", width: 260 },
];

export default function DataTable() {
  const [getAfiliates] = useGetAfiliadosQueryMutation();
  const [afiliates, setAfiliates] = useState([]);
  const [cachedData, setCachedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [afiliateNumber, setAfiliateNumber] = useState("");
  const [state, setState] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);
  const navigate = useNavigate();
  
  const jwt = cookies.user || "not_sent";

  // Estado para controlar el snackbar
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };
  const handleLastnameChange = (event: any) => {
    setLastname(event.target.value);
  };
  const handleAfiliateNumberChange = (event: any) => {
    setAfiliateNumber(event.target.value);
  };
  const handleStateChange = (_, value: string) => {
    setState(value);
  };

  const handleFetchAfiliates = async (page) => {
    setIsLoading(true);

    const filters = {
      name: name,
      lastname: lastname,
      afiliateNumber: afiliateNumber,
      state: state,
      skip: page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    };

    if (cachedData[paginationModel.page] !== undefined) {
      setAfiliates(cachedData[paginationModel.page]);
      setIsLoading(false)
    } else {
      await getAfiliates({ filters, jwt })
        .unwrap()
        .then((response) => {
          setCachedData((prev) => ({
            ...prev,
            [page]: response.data.afiliates,
          }));

          setAfiliates(response.data.afiliates);
          setTotalRows(response.data.count);
        })
        .catch((error) => {
          if (error.status === 400 || error.status === 500) {
            setSnackbar({ open: true, message: "Error de conexión con el servidor" });
            setCachedData({});
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFetchAfiliatesAndCleanCache = async () => {
    setCachedData({});
    setPaginationModel({ page: 0, pageSize: 50 });
    await handleFetchAfiliates(paginationModel.page);
  };

  useEffect(() => {
    const { page } = paginationModel;

    setAfiliates([]);

    handleFetchAfiliates(page);
  }, [paginationModel]);

  const handlePageChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  return (
    <>
      <Paper sx={{ height: "auto", width: "100%", marginTop: 10 }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <TextInput
            label="Nombre"
            handleInputChange={handleNameChange}
            disabled={!!afiliateNumber}
          />
          <TextInput
            label="Apellido"
            handleInputChange={handleLastnameChange}
            disabled={!!afiliateNumber}
          />
          <TextInput
            label="Nro Afiliado"
            handleInputChange={handleAfiliateNumberChange}
          />
          <StateAutocomplete handleSelectionChange={handleStateChange} />
          <Button
            variant="outlined"
            onClick={handleFetchAfiliatesAndCleanCache}
          >
            Buscar
          </Button>
        </Box>
      </Paper>

      <Paper
        sx={{
          height: 650, // Ajuste de altura para mejorar la visualización
          width: "95%", // Reducir el ancho para proporcionar un mejor margen
          margin: "auto", // Centrar el DataGrid
          marginTop: 2,
          padding: 2, // Añadir un poco de padding alrededor
          overflow: "auto", // Habilitar el scroll si es necesario
        }}
      >
        <DataGrid
          getRowId={(row) => row.id__}
          rows={afiliates}
          loading={isLoading}
          rowCount={totalRows}
          columns={columns.map((col) => ({
            ...col,
            flex: 1, // Hace que las columnas se adapten proporcionalmente al espacio disponible
            minWidth: 100, // Ajusta el ancho mínimo para evitar que las columnas sean demasiado estrechas
          }))}
          disableColumnMenu
          disableColumnSorting
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          checkboxSelection={false}
          rowSelection={false}
          pageSizeOptions={[50]}
          sx={{
            "& .MuiDataGrid-root": {
              minWidth: 300, // Ajuste del ancho mínimo del DataGrid para acomodar las columnas
            },
            "& .MuiDataGrid-cell": {
              padding: "6px 8px", // Ajusta el padding en las celdas para una vista más compacta
              fontSize: "0.85rem", // Ajusta el tamaño de la fuente para una mejor densidad de datos
            },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "0.9rem", // Tamaño de la fuente de los encabezados
              backgroundColor: "#f5f5f5", // Color de fondo para diferenciar encabezados
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#fafafa", // Color de fondo para el pie de página del DataGrid
            },
          }}
        />
      </Paper>

      <PositionedSnackbar
        open={snackbar.open}
        message={snackbar.message}
      />
    </>
  );
}
