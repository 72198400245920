import React, { useState } from "react";

import "../index.css";

export const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const headerStyle: React.CSSProperties = {
    position: "relative",
    top: -160,
    width: "100%",
    height: "50vh", // Asegúrate de que el header tenga una altura definida
    overflow: "hidden", // Asegura que la imagen no se desborde
    zIndex: 1, // Eleva el header sobre elementos en conflicto
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black", // Temporariamente para visualizar el área ocupada
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Asegura que la imagen cubra todo el contenedor sin deformarse
    position: "absolute", // Evita que la imagen afecte otros elementos

    top: 0,
    left: 0,
    zIndex: -1, // Envía la imagen al fondo
  };

  const carouselCaptionStyle: React.CSSProperties = {
    zIndex: 2, // Asegura que el texto esté sobre la imagen
    color: "white",
    textAlign: "center", // Alinea el contenido al centro
    textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)", // Sombra para mejor legibilidad
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semi-transparente
    padding: "300px", // Ajusta el tamaño del fondo alrededor del texto
    width: "100%", // Asegura que ocupe todo el ancho del contenedor
    display: "flex", // Flexbox para alineación
    justifyContent: "center", // Centra el contenido horizontalmente
    alignItems: "center", // Centra verticalmente
  };

  const underlineCustom: React.CSSProperties = {
    position: "relative",
    display: "inline-block",
    textDecoration: "underline",
    right: 0,
    bottom: "-3px",
    width: "100%",
    height: "4px",
  };

  const underlineCustomAfter: React.CSSProperties = {
    position: "relative",
    display: "inline-block",
    textDecoration: "underline",
    right: 0,
    bottom: "-3px",
    width: "100%",
    height: "4px",
  };

  return (
    <header id="header" style={headerStyle}>
      <img src="/intro-bg.jpg" alt="Background" style={imageStyle} />

      <div style={carouselCaptionStyle}>
        <h5 className="h5-custom">
          Seguimiento de pedidos{" "}
          <span className="underline-custom">en tiempo real</span>.
        </h5>

        <button onClick={handleOpenModal} className="button-custom">
          + info
        </button>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>¿Cómo funciona la app?</h2>
            <p>
              Esta aplicación permite a los usuarios de la Obra social verificar
              el estado en tiempo real de medicamentos. Los usuarios pueden
              realizar búsquedas precisas utilizando el nombre, apellido, el
              número de afiliado, o el estado del pedido. El objetivo es
              proporcionar una forma sencilla y rápida de acceder a la
              información del seguimiento de pedidos, para una mejor atencion a
              los afiliados.
            </p>
          </div>
        </div>
      )}
    </header>
  );
};
