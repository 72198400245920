import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import MainPage from "../Pages/MainPage.tsx";
import AfiliatePage from "../Pages/AfiliadosPage.tsx";
import Home from "../componentes/home.tsx";
import Navbar from "../componentes/Navbar.tsx";

const SiteRoutes = () => {
  const location = useLocation();
  return (
    <>

      {location.pathname !== "/login" && <Navbar />}

      <Routes>
 
        <Route
          path="/"
          element={<Navigate to="/login" replace />}
        />


        <Route path="/login" element={<MainPage />} />

  
        <Route path="/home" element={<Home />} />
        <Route path="/afiliates" element={<AfiliatePage />} />

        
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
};

export default SiteRoutes;